import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createProduct, updateProduct, createOrUpdateProduct, getProductDetails } from "../../redux/actions/product";


import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import ColorPicker from './ColorPicker';
//import MultiSelector from "./MultiSelect";
import TagInput from "./TagInput";
import QuantityPriceInput from "./QuantityPriceInput";
import PackagingInput from "./PackageInput";
import axios from "axios";



const CreateProduct = () => {
  const { id } = useParams(); // Get the product ID from the URL (for updating)
  const { seller } = useSelector((state) => state.seller);

  const { allProducts,success,error } = useSelector((state) => state.products);
  const { allEvents } = useSelector((state) => state.events);
  const [data, setData] = useState(null);


  useEffect(() => {

    const data = allProducts && allProducts.find((i) => i._id === id);
    setData(data);

  }, [allProducts, id]);



  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [stock, setStock] = useState();
  const [maxOrder, setMaxOrder] = useState();
  const [minOrder, setMinOrder] = useState();
  const [priceInput, setPriceInput] = useState('');
  const [samplePriceInput, setSamplePriceInput] = useState('');
  const [metrics, setMetrics] = useState('');
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [isCartChecked, setIsCartChecked] = useState(false);
  const [isContactChecked, setIsContactChecked] = useState(false);

  const handleCartChange = (e) => {
    setIsCartChecked(e.target.checked);
  };

  const handleContactChange = (e) => {
    setIsContactChecked(e.target.checked);
  };
  useEffect(() => {
    if (data && id) {
      setIsCartChecked(data.shoppingOptions?.cart || false);
      setIsContactChecked(data.shoppingOptions?.contact || false);
    }
  }, [data]);


  useEffect(() => {
    if (id) {
      // If we are in update mode, fetch the product details
      dispatch(getProductDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (data && id) {
      // Set existing data in form for update
      setName(data.name || '');
      setDescription(data.description || '');
      setCategory(data.category || '');
      setTags(data.tags || []);
      setStock(data.stock || '');
      setSelectedColor(data.selectedColor || '#000000');
      setImages(data.images ? data.images.map(img => img.url) : []);

      // Set dynamic inputs like price, sample price, packaging
      setPriceInput(data.priceInput || []);
      setSamplePriceInput(data.samplePriceInput || []);
      setPackaging(data.packaging || []);

      // Set checkout options
      setIsCartChecked(data.shoppingOptions?.cart || false);
      setIsContactChecked(data.shoppingOptions?.contact || false);
    }
  }, [data, id]);
  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };



  useEffect(() => {
    if (success) {


      console.log('success',success)
      toast.success(id ? "Product updated successfully!" : "Product created successfully!");
      navigate('/dashboard-products');
      dispatch({ type: "clearSuccess" });
    }

    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
  }, [success, error, navigate, dispatch, id]);

  // const handleImageChange = (e) => {
  //   const files = Array.from(e.target.files);

  //   setImages([]);

  //   files.forEach((file) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         setImages((old) => [...old, reader.result]);
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
  
    for (const file of files) {
        const resizedImage = await resizeImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImages((prevImages) => [...prevImages, reader.result]); // Base64 encoded image
        };
        reader.readAsDataURL(resizedImage);
    }
};

// Function to resize image using canvas
const resizeImage = (file) => {
    return new Promise((resolve) => {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        img.onload = () => {
            const MAX_WIDTH = 200;
            const MAX_HEIGHT = 200;
            let width = img.width;
            let height = img.height;

            // Calculate the new dimensions
            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob(resolve, 'image/jpeg', 0.8); // Adjust quality as needed
        };

        img.src = URL.createObjectURL(file);
    });
};

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };
  const handlePriceChange = (newValue) => {
    setPriceInput(newValue);
  };
  const handleSamplePriceChange = (newValue) => {
    setSamplePriceInput(newValue);
  };
  const handlePackagingChange = (newValue) => {

    console.log('this has changed', newValue)
    setPackaging(newValue);

  };



  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();
    console.log('67', priceInput);
    images.forEach((image, index) => {
      newForm.append(`images[${index}]`, image); 
    });    
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("tags", tags);
    // newForm.append("minOrder", minOrder);
    // newForm.append("maxOrder", maxOrder);
    newForm.append("isCartChecked", isCartChecked);
    newForm.append("isContactChecked", isContactChecked);
    // newForm.append("discountPrice", discountPrice);
    newForm.append("metrics", metrics);
    newForm.append("selectedColor", selectedColor);
    newForm.append("packaging", packaging);
    newForm.append("stock", stock);
    newForm.append("shopId", seller._id);
    newForm.append("priceInput", JSON.stringify(priceInput));
    newForm.append("samplePriceInput", JSON.stringify(samplePriceInput));
    console.log('68', priceInput, selectedColor, packaging, samplePriceInput);

    const productData = {
      name,
      description,
      category,
      tags,
      stock,
      shopId: seller._id,
      images,
      priceInput,
      samplePriceInput,
      metrics,
      selectedColor,
      packaging,
      // minOrder,
      // maxOrder,
      isCartChecked,
      isContactChecked
    };

    dispatch(createOrUpdateProduct(productData, !!id, id ? id : null));

  };

  return (
    <div className="w-full 600px:w-[60%] bg-white  shadow h-[90vh] rounded-[4px] p-5 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">{!id ? "Create Product" : "Update Product"}</h5>
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your product name..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your product description..."
          ></textarea>
        </div>
        <br />

        <div className="flex flex-col md:flex-row">
          <div className="flex-1 md:mr-2 mb-2 md:mb-0">
            <label className="pb-2">
              Category <span className="text-red-500">*</span>
            </label>
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="Choose a category">Choose a category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>

          <div className="flex-1 md:ml-2">
            <label className="pb-2">Tags</label>
            <TagInput label="Tags" tags={tags} setTags={setTags} />
          </div>
        </div>

        <br />
        <div>
          <label className="pb-2">Packaging</label>
          <PackagingInput onPackagingChange={handlePackagingChange} currency={seller.currency} initialData={packaging} />
        </div>
        <br />

        <br />
        <div>
          <label className="pb-2">
            Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <QuantityPriceInput onPriceChange={handlePriceChange} initialData={priceInput} />
          <br />
          <hr />
          <br />
          <label className="pb-2 mt-5">
            Sample Product Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <QuantityPriceInput onPriceChange={handleSamplePriceChange} initialData={samplePriceInput} />
        </div>
        <br />

        <div className="flex flex-col md:flex-row">
          <div className="flex-1 md:mr-2 mb-2 md:mb-0">
            <label className="pb-2">
              Product Stock <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="price"
              value={stock}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setStock(e.target.value)}
              placeholder="Enter your product stock..."
            />
          </div>

          <div className="flex-1 md:ml-2">
            <label className="block pb-2">Pick Your Color</label>
            <ColorPicker
              defaultColor={selectedColor} // Replace with the initial color you want to load
              onColorChange={handleColorChange}
            />

          </div>
        </div>
        <br />


        <div className="flex flex-col md:flex-row">
          <div className="flex-1 md:mr-2 mb-2 md:mb-0">
            <label className="pb-2">
              Upload Images <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              id="upload"
              className="hidden"
              multiple
              onChange={handleImageChange}
            />
            <div className="w-full flex items-center flex-wrap">
              <label htmlFor="upload">
                <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
              </label>

              {/* Preview valid images */}
              {/* {images} */}
              {images && images.map((image, index) => (
                image && (
                  <div key={`${image}-${index}`} className="relative m-2">
                    <img
                      src={image}
                      alt="Preview"
                      className="h-[120px] w-[120px] object-cover"
                      onError={(e) => { e.target.style.display = 'none'; }}  // Hide broken images
                    />
                    {/* Delete button */}
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full"
                    >
                      X
                    </button>
                  </div>
                )
              ))}



            </div>
            <br />

          </div>

          <div className="flex-1 md:ml-2">
            <label className="pb-2">
              Checkout Option <span className="text-red-500">*</span>
            </label>
            <div className="flex items-center space-x-6 mt-2">
              <div className="flex items-center">

                <input
                  id="cart-checkbox"
                  type="checkbox"
                  checked={isCartChecked}
                  onChange={handleCartChange}
                  className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                />
                <label htmlFor="cart-checkbox" className="ml-2 text-sm font-medium text-gray-700">Shopping Cart</label>
              </div>
              <div className="flex items-center">
                <input
                  id="contact-checkbox"
                  type="checkbox"
                  checked={isContactChecked}
                  onChange={handleContactChange}
                  className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                />
                <label htmlFor="contact-checkbox" className="ml-2 text-sm font-medium text-gray-700">Direct Contact</label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <input
              type="submit"
              value="Create"
              className="mt-4 cursor-pointer block w-full px-3 h-10 border border-gray-300 rounded-md placeholder-gray-400 bg-blue-500 text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </form >
    </div >);
};

export default CreateProduct;
