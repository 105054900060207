import React, { useState, useEffect } from 'react';
import { FaTrash, FaPlus, FaTags } from 'react-icons/fa';
import Select from 'react-select';

const QuantityPriceInput = ({ onPriceChange, currency, initialData = [] }) => {
  // If there's initial data, use it; otherwise, set default empty range.
  const [ranges, setRanges] = useState(initialData.length > 0 ? initialData : [{ min: '', max: '', price: '', discountPrice: '', metric: '' }]);
  const [errors, setErrors] = useState([]);
  const [priceError, setPriceError] = useState(false);

  const options = [
    { value: 'kilogram', label: 'Kg' },
    { value: 'litre', label: 'Litres' },
    { value: 'piece', label: 'Pieces' },
    { value: 'gram', label: 'Grams' },
    { value: 'millilitre', label: 'Millilitres' },
    { value: 'ton', label: 'Tons' },
    { value: 'cubic_meter', label: 'Cubic Meters' },
    { value: 'cubic_centimeter', label: 'Cubic Centimeters' },
    { value: 'cubic_feet', label: 'Cubic Feet' },
    { value: 'pound', label: 'Pounds' },
    { value: 'ounce', label: 'Ounces' },
    { value: 'gallon', label: 'Gallons' },
    { value: 'quart', label: 'Quarts' },
    { value: 'barrel', label: 'Barrels' },
    { value: 'bundle', label: 'Bundles' },
    { value: 'dozen', label: 'Dozens' },
    { value: 'box', label: 'Boxes' },
    { value: 'pallet', label: 'Pallets' },
  ];

  // Handle changes to the select dropdown for metric
  const handleChange = (selectedOption, index) => {
    const newRanges = [...ranges];
    newRanges[index].metric = selectedOption.value;
    setRanges(newRanges);
    onPriceChange(newRanges);  // Pass updated ranges to parent
  };

  // Handle changes to input fields like min, max, price, discountPrice
  const handleRangeChange = (index, field, value) => {
    const newRanges = [...ranges];
    newRanges[index][field] = value;
    setRanges(newRanges);

    // Validation checks
    const errorsArray = [];

    if (Number(newRanges[index].min) >= Number(newRanges[index].max)) {
      errorsArray.push('Min should be less than Max.');
    }
    if (Number(newRanges[index].min) <= 0) {
      errorsArray.push('Min should be greater than 0.');
    }
    if (Number(newRanges[index].discountPrice) !== '' && Number(newRanges[index].discountPrice) >= Number(newRanges[index].price)) {
      errorsArray.push('Discount Price should be less than Price.');
    }

    // Update errors and propagate change
    setErrors(errorsArray);
    setPriceError(errorsArray.length > 0);
    onPriceChange(newRanges);  // Pass updated ranges to parent
  };

  const addRange = () => {
    if (errors.length > 0) {
      setPriceError(true);
      return;
    }

    const newRange = { min: '', max: '', price: '', discountPrice: '', metric: '' };
    setRanges([...ranges, newRange]);
    setErrors([...errors, []]);
    setPriceError(false);
  };

  const removeRange = (index) => {
    const newRanges = [...ranges];
    newRanges.splice(index, 1);
    setRanges(newRanges);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);

    setPriceError(newErrors.length > 0);
    onPriceChange(newRanges);  // Pass updated ranges to parent
  };

  // If you have `initialData` that needs to be set when the component mounts
  useEffect(() => {
    if (initialData.length > 0) {
      setRanges(initialData);
    }
  }, [initialData]);

  return (
    <div>
      {ranges.map((range, index) => (
        <div key={index} className="flex flex-col md:flex-row items-center mb-4">
          <div className="flex flex-col md:flex-row items-center w-full md:w-auto mb-2 md:mb-0">
            <Select
              options={options}
              value={options.find(option => option.value === range.metric)}
              onChange={(selectedOption) => handleChange(selectedOption, index)}
              className="mb-2 md:mb-0 md:mr-4 w-full md:w-auto"
            />
            <input
              type="number"
              value={range.min}
              onChange={(e) => handleRangeChange(index, 'min', e.target.value)}
              placeholder="Min Qty"
              className="mb-2 md:mb-0 md:mr-2 px-3 py-1 border border-gray-300 rounded w-full md:w-24"
            />
            <div className="bg-gray-600 w-6 h-10 flex justify-center items-center text-white">-</div>
            <input
              type="number"
              value={range.max}
              onChange={(e) => handleRangeChange(index, 'max', e.target.value)}
              placeholder="Max Qty"
              className="mb-2 md:mb-0 md:ml-2 px-3 py-1 border border-gray-300 rounded w-full md:w-24"
            />
          </div>

          <div className="flex flex-col md:flex-row items-center w-full md:w-auto mb-2 md:mb-0 md:ml-4">
            <span className="flex justify-center items-center text-green-600 font-bold ">@ {currency} </span>
            <input
              type="number"
              value={range.price}
              onChange={(e) => handleRangeChange(index, 'price', e.target.value)}
              placeholder="Price"
              className="mb-2 md:mb-0 md:ml-1 px-2 py-1 border border-gray-300 rounded-l w-full md:w-24"
            />
            <div className="bg-gray-600 w-6 h-10 flex justify-center items-center text-white "><FaTags /></div>
            <input
              type="number"
              value={range.discountPrice}
              onChange={(e) => handleRangeChange(index, 'discountPrice', e.target.value)}
              placeholder="Discount Price"
              className="mb-2 md:mb-0 px-2 py-1 border border-gray-300 rounded-r w-full md:w-24"
            />
          </div>

          <div className="flex flex-row md:flex-col items-center">
            {index !== 0 && (
              <button
                type="button"
                onClick={() => removeRange(index)}
                className="ml-1 px-2 py-1 mb-2 md:mb-0 bg-red-500 text-white rounded hover:bg-red-600"
              >
                <FaTrash />
              </button>
            )}
            <button
              type="button"
              onClick={addRange}
              className="ml-1 px-2 py-1 mb-2 md:mb-0 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              <FaPlus />
            </button>
          </div>
        </div>
      ))}
      <div className="mb-2">
        {errors && errors.map((error, errorIndex) => (
          <p key={errorIndex} className="text-red-500 ml-2">{error}</p>
        ))}
      </div>
    </div>
  );
};

export default QuantityPriceInput;
